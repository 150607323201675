import { action, computed, makeObservable, observable } from 'mobx';

import { mapApproach } from 'src/api/new-well/serializers/approaches-serializers';
import { TIdTriple } from 'src/api/new-well/types';
import { getRandomNumber } from 'src/shared/utils/get-random-number';
import { isObjectWithKeys } from 'src/shared/utils/is-object-with-keys';
import { RootStore } from 'src/store';

import { Item, Tab, ValidatableItem } from '../../../shared/entities/abstract-control-entities';
import { Label } from '../../../shared/entities/control-entities';
import { FormPlugin } from '../plugins';
import { ValidationManager } from '../validation/validation-manager';

import { Approach, ApproachesTab, ApproachStage, ApproachStageSection } from './approach-entities';
import { ColumnTab } from './column-tab.entity';
import { TForm, TSerializedFormValues } from './types';

export type TProcessFormFieldsData = {
  excludeApproachesTab?: boolean;
  excludeDisabledTabs?: boolean;
};

function checkIsValueValid(value: unknown, excludeFalsyValues: boolean): boolean {
  if (!excludeFalsyValues) {
    return true;
  } else {
    if (value !== null && value !== 'null' && value !== undefined && value !== 'undefined') {
      return true;
    }
    return false;
  }
}

export class FormStore {
  @observable tabs: Tab[];
  @observable activeTab: Tab;
  @observable isEditingMode = false;
  @observable isPluginsInitialized = false;
  @observable _isLoading = false;
  @observable pendingEvents: number[] = [];
  @observable isUnplanned = false;

  readonly fields: Record<string, Item>;
  readonly id: number;
  readonly validationManager: ValidationManager;
  readonly plugins: FormPlugin[];
  private readonly rootStore: RootStore;

  constructor(data: TForm, rootStore: RootStore, plugins: FormPlugin[] = []) {
    this.id = getRandomNumber();
    this.tabs = data.tabs;
    this.activeTab = data.tabs[0];
    this.fields = this.collectFields();
    this.plugins = plugins;
    this.rootStore = rootStore;
    this.validationManager = new ValidationManager(this, this.rootStore);

    makeObservable(this);
  }

  private connectPluginsWithForm(): VoidFunction {
    const disposers: VoidFunction[] = [];

    this.plugins.forEach((plugin) => {
      const disposer = plugin.connect(this);
      if (disposer) {
        disposers.push(disposer);
      }
    });

    this.setIsPluginsInitialized(true);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }

  @action.bound
  private setIsPluginsInitialized(is: boolean) {
    this.isPluginsInitialized = is;
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading || !!this.pendingEvents.length;
  }

  @action.bound
  setIsLoading(is: boolean): void {
    this._isLoading = is;
  }

  @action.bound
  addPendingEvent(id: number): void {
    this.pendingEvents.push(id);
  }

  @action.bound
  removePendingEvent(id: number): void {
    const pendingEventIndex = this.pendingEvents.indexOf(id);
    if (pendingEventIndex >= 0) {
      this.pendingEvents.splice(pendingEventIndex, 1);
    }
  }

  @computed
  get approachesTab(): ApproachesTab | null {
    return this.tabs.find((tab): tab is ApproachesTab => tab instanceof ApproachesTab) || null;
  }

  @computed
  get approaches(): Approach[] {
    return this.approachesTab?.approachesList.approaches || [];
  }

  @computed
  get stages(): ApproachStage[] {
    return this.approaches.map((approach) => approach.stagesList.stages).flat();
  }

  @computed
  get sections(): ApproachStageSection[] {
    return this.stages.map((stage) => stage.sectionsList.sections).flat();
  }

  @action.bound
  setIsEditingMode(is: boolean): void {
    this.isEditingMode = is;
  }

  @action.bound
  addNewApproach(): void {
    this.approachesTab?.addNewApproach();
  }

  @action.bound
  setRigOperationsIds(ids: TIdTriple[]): void {
    const rigOperationIds = ids.filter((triple) => triple.objectType === 'GOplan_RigOperation');
    if (rigOperationIds.length === 1 && !rigOperationIds[0].oldId) {
      const newRigOperationId = rigOperationIds[0].id;
      this.approaches.forEach((approach) => {
        approach.setRigOperationId(newRigOperationId);
      });
    } else {
      this.approaches.forEach((approach) => {
        const newRigOperationId = rigOperationIds.find((rigOpId) => {
          if (approach.rigOperationId) {
            return approach.rigOperationId === rigOpId.oldId;
          } else {
            return rigOpId.oldId === null;
          }
        })?.id;

        if (newRigOperationId) {
          approach.setRigOperationId(newRigOperationId);
        }
      });
    }
  }

  @action.bound
  setActiveTab(id: string): void {
    const newActiveTab = this.tabs.find((tab) => tab.formElementRefId === id);
    if (!!newActiveTab) this.activeTab = newActiveTab;
  }

  private collectFields(): Record<string, Item> {
    const fields: Record<string, Item> = {};

    const collect = (item: Item) => {
      fields[item.fieldId] = item;
      if (item.formElementRefId) {
        fields[item.formElementRefId] = item;
      }
    };

    this.processFormFields(collect);

    return fields;
  }

  processFormFields = (callback: (item: Item) => void, options?: TProcessFormFieldsData): void => {
    for (let tab of this.tabs) {
      if (options?.excludeDisabledTabs ? !tab.isDisabled : true) {
        if (tab instanceof ColumnTab) {
          for (let column of tab.columns) {
            for (let item of column.items) {
              callback(item);
            }
          }
        }
        if (tab instanceof ApproachesTab && !options?.excludeApproachesTab) {
          tab.approachesList.approaches.forEach((approach) => {
            approach.fieldsList.forEach((field) => callback(field));
            approach.stagesList.stages.forEach((stage) => {
              stage.fieldsList.forEach((field) => callback(field));
              stage.sectionsList.sections.forEach((section) => {
                section.fieldsList.forEach((field) => callback(field));
              });
            });
          });
        }
      }
    }
  };

  @computed
  get progressBarFieldsCount(): [number, number] {
    let useInProgressberFieldsCount = 0;
    let filledFieldsCount = 0;

    const checkDoFieldCounts = (item: Item): void => {
      if (!item.isDisabled) {
        if (item instanceof ValidatableItem) {
          if (item.useInMainProgressBar) {
            useInProgressberFieldsCount++;
            if (item.isReady) filledFieldsCount++;
          }
        }
      }
    };
    this.processFormFields(checkDoFieldCounts, { excludeDisabledTabs: true });

    return [useInProgressberFieldsCount, filledFieldsCount];
  }

  @action.bound
  setServerErrors(errors?: Record<string, string>): void {
    const setError = (item: Item): void => {
      if (!item.isDisabled) {
        if (item instanceof ValidatableItem) {
          if (!!errors && item.fieldId in errors) {
            item.setError(errors[item.fieldId]);
          }
        }
      }
    };

    this.processFormFields(setError, { excludeDisabledTabs: true });
  }

  @action.bound
  reset(): void {
    const resetControl = (item: Item) => {
      item.clearItem();
    };

    this.processFormFields(resetControl, { excludeDisabledTabs: true });
    const approachesTab = this.approachesTab;
    if (!approachesTab || !(approachesTab instanceof ApproachesTab)) return;
    approachesTab.approachesList.approaches.forEach((approach) =>
      approachesTab.approachesList.deleteApproach(approach.id)
    );
  }

  @action.bound
  setFormValues(values: TSerializedFormValues, excludeFalsyValues = false): void {
    for (const [key, fieldValue] of Object.entries(values)) {
      const control = this.fields[key];

      if (!control) {
        continue;
      }

      const isValidValue = checkIsValueValid(fieldValue, excludeFalsyValues);

      if (
        isValidValue &&
        !control.tryToSetRawValue(fieldValue, true) &&
        fieldValue !== undefined &&
        !(control instanceof Label)
      ) {
      } else {
        control.setInitialValue(control.value);
      }
    }
    // //TODO: Отрефакторить данный метод после рефакторинга генерации подходов, этапов и секций.
    const approaches = values.GOplan_RigOperations || values['GOplan_RigOperation.approaches'];
    if (approaches?.length) {
      const approachesList = this.approachesTab?.approachesList;
      if (!approachesList) return;
      const approachesControls: Approach[] = [];

      approaches
        .sort(
          (firstAppr, secondAppr) =>
            Number(firstAppr[approachesList.approachReference.indexAttrName]) -
            Number(secondAppr[approachesList.approachReference.indexAttrName])
        )
        .forEach((rawApproach) => {
          if (isObjectWithKeys(rawApproach)) {
            const newApproach = mapApproach(
              {
                approachReference: approachesList.approachReference,
                directories: this.rootStore.directories,
              },
              rawApproach
            );

            const rigOpId = rawApproach[approachesList.approachReference.rigOperationIdAttrName];
            if (typeof rigOpId === 'number') {
              newApproach.setRigOperationId(rigOpId);
            }

            approachesControls.push(newApproach);
          }
        });
      approachesList.setApproaches(approachesControls);
    }
  }

  @action.bound
  init(): VoidFunction | void {
    if (this.isPluginsInitialized) {
      return;
    }

    const pluginsDisposer = this.connectPluginsWithForm();

    return () => {
      pluginsDisposer();
    };
  }
}
