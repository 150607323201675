import { action, autorun, makeObservable } from 'mobx';

import { hasValue } from 'src/shared/utils/common';
import { RootStore } from 'src/store';

import { Item } from '../../../../shared/entities/abstract-control-entities';
import { PassingIntervalField } from '../../../../shared/entities/control-entities';
import { FormStore } from '../../entities/form.entity';
import { FormPlugin } from '../abstract-form-plugin.entity';

export class PassingValueFormulaPlugin extends FormPlugin {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action.bound
  connect(form: FormStore): VoidFunction {
    const disposers: VoidFunction[] = [];

    const processItem = (item: Item) => {
      if (item.fieldId === 'passingValue') {
        const approachesList = form.approachesTab?.approachesList;

        if (!!approachesList) {
          const disposer = autorun(
            () => {
              if (item.value === 0) {
                item.setValue(null);
              }

              const isItemDisabled = (() => {
                return (
                  approachesList.approaches.length > 1 ||
                  (!!approachesList.approaches.length &&
                    approachesList.approaches[0].stagesList.stages.some((s) => s.sectionsList.sections.length))
                );
              })();

              if (isItemDisabled) {
                item.setIsVisuallyDisabled({ flagId: `speedPerDayFormula_${item.fieldId}`, value: true });
              } else {
                item.setIsVisuallyDisabled({ flagId: `speedPerDayFormula_${item.fieldId}`, value: false });

                return;
              }

              const totalPassingValue = approachesList.approaches.reduce((prevAppr: number | null, currAppr) => {
                const currApprPassingSum = currAppr.stagesList.stages.reduce((prevStage: number | null, currStage) => {
                  if (!currStage.sectionsList.sections.length) {
                    const passingValue = currStage.passingField?.value ?? null;

                    if (!hasValue(prevStage)) {
                      return passingValue;
                    } else {
                      return hasValue(passingValue) ? prevStage + passingValue : prevStage;
                    }
                  }

                  const sectionsPassingSum = currStage.sectionsList.sections.reduce(
                    (prevSec: number | null, curSec) => {
                      const passingField = curSec.fieldsList.find(
                        (field): field is PassingIntervalField => field instanceof PassingIntervalField
                      );

                      if (!hasValue(prevSec)) {
                        return passingField?.passingValue ?? null;
                      } else {
                        return hasValue(passingField?.passingValue) && passingField
                          ? prevSec + passingField.passingValue
                          : prevSec;
                      }
                    },
                    null
                  );

                  currStage.passingField?.tryToSetRawValue(sectionsPassingSum);

                  if (!hasValue(prevStage)) {
                    return sectionsPassingSum;
                  } else {
                    return hasValue(sectionsPassingSum) ? prevStage + sectionsPassingSum : prevStage;
                  }
                }, null);

                if (!hasValue(prevAppr)) {
                  return currApprPassingSum;
                } else {
                  return hasValue(currApprPassingSum) ? prevAppr + currApprPassingSum : prevAppr;
                }
              }, null);
              item.tryToSetRawValue(totalPassingValue);
            },
            { name: 'PassingValueFormulaPlugin' }
          );
          disposers.push(disposer);
        }
      }
    };

    form.processFormFields(processItem);

    return () => {
      disposers.forEach((disposer) => disposer());
    };
  }
}
