import {
  Approach,
  ApproachesTab,
  ApproachStage,
  ApproachStageSection,
} from 'src/features/well-form/entities/approach-entities';
import { ColumnTab } from 'src/features/well-form/entities/column-tab.entity';
import { Item, Tab } from 'src/shared/entities/abstract-control-entities';
import { DateIntervalField, PassingIntervalField } from 'src/shared/entities/control-entities';
import { hasValue } from 'src/shared/utils/common';
import { isNumberArray } from 'src/shared/utils/is-number-array';
import { isStringArray } from 'src/shared/utils/is-string-array';
import { isStringNumberOrBoolean } from 'src/shared/utils/is-string-number-or-boolean';

import { TSerializedForm, TSerializedApproach, TSerializedStage, TSerializedSection, TIdTriple } from '../types';

type TSerializedFields = Record<string, string | number | boolean | string[] | number[] | null>;

const serializeFields = (items: Item[], byFieldId?: boolean): TSerializedFields => {
  const serializedFields: TSerializedFields = {};
  for (let item of items) {
    if (item.formElementRefId) {
      if (
        isStringNumberOrBoolean(item.value) ||
        !hasValue(item.value) ||
        isStringArray(item.value) ||
        isNumberArray(item.value)
      ) {
        if (byFieldId) {
          serializedFields[item.fieldId] = hasValue(item.value) ? item.value : null;
          continue;
        }
        if (!['Common_Well.wellNumber', 'GOplan_RigOperation.passingValue'].includes(item.formElementRefId)) {
          serializedFields[item.formElementRefId] = hasValue(item.value) ? item.value : null;
        }
      }
    }
    if (item instanceof DateIntervalField) {
      if (item.startDate) {
        serializedFields['startDate'] = item.startDate.unix();
      }
      if (item.endDate) {
        serializedFields['endDate'] = item.endDate.unix();
      }
      if (item.duration !== null) {
        serializedFields['duration'] = item.duration.valueOf();
      }
    }

    if (item instanceof PassingIntervalField) {
      if (item.passingValueMin !== null) {
        serializedFields['passingValueMin'] = item.passingValueMin;
      }
      if (item.passingValueMax !== null) {
        serializedFields['passingValueMax'] = item.passingValueMax;
      }
      if (item.passingValue !== null) {
        serializedFields['passingValue'] = item.passingValue;
      }
    }
  }

  return serializedFields;
};

export const serializeApproach = (approach: Approach, index?: number): TSerializedApproach => {
  const serializedApproach: TSerializedApproach = {
    ...serializeFields(approach.fieldsList),
    'GOplan_RigOperation.stages': [],
  };
  if (hasValue(index)) {
    serializedApproach[approach.indexAttrName] = index;
  }
  if (approach.rigOperationId) {
    serializedApproach[approach.rigOperationIdAttrName] = approach.rigOperationId;
  }
  const serializedStages: TSerializedStage[] = [];

  approach.stagesList.stages.forEach((stage, index) => {
    serializedStages.push(serializeStage(stage, index));
  });
  serializedApproach[approach.stagesList.attrName] = serializedStages;

  return serializedApproach;
};

export const serializeStage = (stage: ApproachStage, index?: number): TSerializedStage => {
  const serializedStage: TSerializedStage = {
    ...serializeFields(stage.fieldsList, true),
    wellLifeCycleStageId: Number(stage.stageType.id),
  };

  if (hasValue(index)) {
    serializedStage.index = index;
  }

  if (stage.stageType.data.hasSections) {
    const serializedSections: TSerializedSection[] = [];

    stage.sectionsList.sections.forEach((section) => {
      serializedSections.push(serializeSection(section));
    });
    serializedStage[stage.sectionsList.attrName] = serializedSections;
  }

  return serializedStage;
};

export const serializeSection = (section: ApproachStageSection): TSerializedSection => {
  const serializedSection: TSerializedSection = {
    ...serializeFields(section.fieldsList, true),
    sectionTypeId: Number(section.type.id),
  };

  return serializedSection;
};

export const serializeFormForRequest = (tabs: Tab[], tripleIds?: TIdTriple[] | null): string => {
  let serializedForm: TSerializedForm = {};

  tripleIds?.forEach((triple) => {
    if (triple.objectType !== 'GOplan_RigOperation' && triple.objectType !== 'GOplan_PlanWellTriple') {
      serializedForm[`${triple.objectType}.id`] = triple.id;
    }
  });

  for (let tab of tabs) {
    if (tab instanceof ColumnTab) {
      for (let column of tab.columns) {
        serializedForm = { ...serializedForm, ...serializeFields(column.items) };
      }
    }
    if (tab instanceof ApproachesTab) {
      const serializedApproaches: TSerializedApproach[] = [];

      tab.approachesList.approaches.forEach((approach, index) => {
        serializedApproaches.push(serializeApproach(approach, index));
      });

      // Временное решение
      const isSectionDetailing = (() => {
        return (
          tab.approachesList.approaches.length > 1 ||
          (!!tab.approachesList.approaches.length &&
            tab.approachesList.approaches[0].stagesList.stages.some((s) => s.sectionsList.sections.length))
        );
      })();

      serializedForm['GOplan_GeologicalTask.sectionsDetailing'] = isSectionDetailing;

      serializedForm[tab.approachesList.attrName] = serializedApproaches;
    }
  }

  return JSON.stringify(serializedForm);
};
